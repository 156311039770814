"use client";
import Link from "next/link";
import Image from "next/image";
import { FooterInfoItem } from "./footer-info-item";
import { useOsPlatform } from "../../../hooks/use-os-platform";

export const Footer = () => {
  const { isIos, isAndroid, isDefault, searchParams } = useOsPlatform();

  return (
    <footer className="w-full z-20 bg-white">
      <div className="flex flex-col lg:grid lg:grid-cols-4 justify-center lg:justify-between text-black p-8 lg:px-20 lg:pt-16 bg-white border-t">
        <div className="flex flex-col justify-between text-[12px] lg:text-[20px] items-start">
          <Image
            src="/landing/img/logo/logo-horiz-black.svg"
            className="hidden lg:block"
            alt="logo"
            width={190}
            height={46.82}
          />
          <Image
            src="/landing/img/logo/logo-horiz-black.svg"
            className="block lg:hidden"
            alt="logo"
            width={120}
            height={46.82}
          />
          <div className="lg:hidden flex pt-4 text-[12px] leading-[21.6px]">
            Asisten Virtual berbasis Artificial Intelligence (AI) yang membantu para penjual memulai dan menjalankan usaha online atau offline.<br /><br />
            AI Jooalan membantu para penjual menemukan produk terbaik untuk dijual, membuat gambar produk, hingga mencari supplier terbaik.<br /><br />
            Semua bisa berjualan dengan Jooalan!
          </div>
          <div className="hidden lg:flex lg:flex-col mb-24">
            <div className="pt-12 font-[700]">Unduh Aplikasi Sekarang!</div>
            <div className="flex items-center gap-x-1">
              {(isAndroid || isDefault) && (
                <Link href={process.env.NEXT_PUBLIC_PLAY_STORE ?? "/"}>
                  <Image
                    src="/landing/img/play-store.png"
                    width={147}
                    height={57}
                    alt="Play Store"
                    className="cursor-pointer"
                  />
                </Link>
              )}
              {(isIos || isDefault) && (
                <Link href={process.env.NEXT_PUBLIC_APP_STORE ?? "/"}>
                  <Image
                    src="/landing/img/app-store.png"
                    width={147}
                    height={44}
                    alt="App Store"
                    className="cursor-pointer"
                  />
                </Link>
              )}
            </div>
          </div>
        </div>

        <div className="hidden lg:flex flex-col justify-between col-span-2 px-0 lg:px-8 pt-4 lg:pt-0">
          <div className="flex pt-2 text-[16px] leading-[21.6px]">
            Asisten Virtual berbasis Artificial Intelligence (AI) yang membantu para penjual memulai dan menjalankan usaha online atau offline.<br /><br />
            AI Jooalan membantu para penjual menemukan produk terbaik untuk dijual, membuat gambar produk, hingga mencari supplier terbaik.<br /><br />
            Semua bisa berjualan dengan Jooalan!
          </div>
          <div className="flex mb-12 mt-4">
            <Link href="https://www.tiktok.com/@jooalan.id" target="_blank">
              <Image
                src="/landing/img/tiktok.png"
                alt="tiktok"
                width={44}
                height={44}
                className="cursor-pointer"
              />
            </Link>
            <Link href="https://www.youtube.com/@jooalan" target="_blank">
              <Image
                src="/landing/img/yt.png"
                alt="youtube"
                width={44}
                height={44}
                className="cursor-pointer"
              />
            </Link>
            <Link href="https://www.instagram.com/jooalan.id/" target="_blank">
              <Image
                src="/landing/img/ig.png"
                alt="instagram"
                width={44}
                height={44}
                className="cursor-pointer"
              />
            </Link>
          </div>
        </div>

        <div className="flex flex-col lg:pl-4 pt-8 lg:pt-2">
          <FooterInfoItem
            icon="/landing/img/footer-map.svg"
            title="Kantor"
            description={
              <div>
                <div>GoWork Senayan City - Panin Tower Lt.12.</div>
                <div>Jl. Asia Afrika No.Lot. 19, Daerah Khusus Ibukota Jakarta 10270</div>
              </div>
            }
          />
          <FooterInfoItem
            icon="/landing/img/footer-mail.svg"
            title={
              <a className="font-bold" href="mailto:cuan@jooalan.id">
                cuan@jooalan.id
              </a>
            }
          />
          <FooterInfoItem
            icon="/landing/img/footer-phone.svg"
            title={
              <a className="font-bold" href="tel:081910000118">
                0819-10000-118
              </a>
            }
          />
        </div>
        <div className="flex lg:hidden my-4 lg:my-0 mx-0">
          <Link href="https://www.tiktok.com/@jooalan.id" target="_blank">
            <Image
              src="/landing/img/tiktok.png"
              alt="tiktok"
              width={44}
              height={44}
              className="cursor-pointer"
            />
          </Link>
          <Link href="https://www.youtube.com/@jooalan" target="_blank">
            <Image
              src="/landing/img/yt.png"
              alt="youtube"
              width={44}
              height={44}
              className="cursor-pointer"
            />
          </Link>
          <Link href="https://www.instagram.com/jooalan.id/" target="_blank">
            <Image
              src="/landing/img/ig.png"
              alt="instagram"
              width={44}
              height={44}
              className="cursor-pointer"
            />
          </Link>
        </div>
        <div className="flex flex-col lg:hidden">
          <div className="font-bold text-[16px] pb-4">Unduh Aplikasi Sekarang!</div>
          <div className="flex items-center gap-x-1">
            {(isAndroid || isDefault) && (
              <Link href={process.env.NEXT_PUBLIC_PLAY_STORE ?? "/"}>
                <Image
                  src="/landing/img/play-store.png"
                  width={114}
                  height={52}
                  alt="Play Store"
                  layout="fixed"
                />
              </Link>
            )}
            {(isIos || isDefault) && (
              <Link href={process.env.NEXT_PUBLIC_APP_STORE ?? "/"}>
                <Image
                  src="/landing/img/app-store.png"
                  width={118}
                  height={40}
                  alt="App Store"
                  layout="fixed"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center bg-jooalan-primary-100 py-2 text-white">
        <div className="py-2 text-[10px] lg:text-[14px]">
          © Copyright 2024 PT Ruang Dagang Internasional
        </div>
        <div className="flex text-[8px] lg:text-[10px] gap-x-1">
          <Link
            href={`/terms-and-conditions?${searchParams.toString()}`}
            className="underline"
          >
            <span className="cursor-pointer">Terms and Conditions</span>
          </Link>{" "}
          &bull;
          <Link
            href={`/privacy-policy?${searchParams.toString()}`}
            className="underline"
          >
            <span className="cursor-pointer">Privacy Policy</span>
          </Link>{" "}
          &bull;
          <Link href={`/faq?${searchParams.toString()}`} className="underline">
            <span className="cursor-pointer">FAQ</span>
          </Link>
        </div>
      </div>
    </footer>
  );
};
